import { FormControl, FormControlLabel, Grid, Icon, Radio, RadioGroup } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import React, { Component } from "react";
import API, { newBackend } from "../../comm/API";
import GenericButton from "../../components/genericButton/genericButton";
import Table from "../../components/table/table";
import DownloadFilesNewBackend from "../../comm/DownloadFilesNewBackend";

export default class RegistroAnexos extends Component {
  registroTitles = [
    { id: "0", canOrder: false, label: "Parecer DN" },
    { id: "1", canOrder: false, label: "Anexo de Indicadores" },
    { id: "2", canOrder: false, label: "" },
    { id: "3", canOrder: false, label: "" },
  ];

  avaliacaoTitles = [
    { id: "0", canOrder: false, label: "Anexo de Indicadores" },
    { id: "1", canOrder: false, label: "" },
    { id: "2", canOrder: false, label: "Parecer DN" },
  ];

  parecerDNOptions = {
    conforme: { id: 2, chave: "conforme", descricao: "Conforme" },
    naoConforme: { id: 3, chave: "naoconforme", descricao: "Não Conforme" },
  };

  anexoReferencialLines = [];
  anexoReferencialList = [];

  constructor(props) {
    super(props);
    this.state = {
      atendimento: JSON.parse(JSON.stringify(this.props.atendimento)),
      listAnexos: this.props.listAnexos,
      anexosExcluidos: [],
    };
    this.isDN = this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DN";
    this.types = this.isDN ? ["inputNumber", "inputNumber", "icon"] : ["label", "inputNumber", "inputNumber", "label", "icon"];
    this.isPrimeiroAcesso = true;
    this.getAnexosRefresh();
  }

  getAnexoReferencialLines = async () => {
    await API.get("rest/auxiliar/AnexoReferencial")
      .then((res) => {
        this.anexoReferencialList = res.data.sort((i, j) => (i.id > j.id ? 1 : -1));
        this.anexoReferencialList.forEach((anexo, index) => {
          this.anexoReferencialLines.push(this.createLineAnexo(anexo, index));
        });
        this.isPrimeiroAcesso = false;
        this.setState(this.state);
        this.props.closeLoading();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAnexosRefresh = async (index) => {
    if (this.isDN) {
      let atendimento = this.state.atendimento;
      let anexos = atendimento.anexoAtendimentoReferencialList;
      let anexosNovo = this.props.listAnexos;
      if (this.anexoReferencialLines.length === 0) {
        anexos = anexos.sort((curr, other) => (curr.anexoReferencial.id > other.anexoReferencial.id ? 1 : -1));
        await anexos.forEach((anexo, i) => {
          let anexoNovo = anexosNovo.find(item => item.id === anexo.anexoReferencial.id);
          this.anexoReferencialLines.push(this.createLineAvaliacao(anexo, i, anexoNovo));
        });
      } else {
        this.anexoReferencialLines[index] = this.createLineAvaliacao(anexos[index], index, anexosNovo[index]);
      }
    } else if (index >= 0) {
      this.anexoReferencialLines[index] = this.createLineAnexo(this.anexoAtendimentoReferencialList[index], index);
    } else {
      await this.getAnexoReferencialLines();
    }
    this.setState(this.state);
    this.props.closeLoading();
  };

  removerItem = async (index) => {
    let atendimento = this.state.atendimento;
    let anexoAuxList = atendimento.anexoAtendimentoReferencialList.slice();
    anexoAuxList[index] = this.getAnexoAtendimentoRefModel(anexoAuxList[index].anexoReferencial);
    this.state.anexosExcluidos.push(anexoAuxList[index].anexoReferencial);
    atendimento.anexoAtendimentoReferencialList.splice(index, 1);
    atendimento.anexoAtendimentoReferencialList = atendimento.anexoAtendimentoReferencialList.filter((obj) => obj.url !== "");
    this.props.showToast("Anexo removido com sucesso!", "success");
    atendimento.anexoAtendimentoReferencialList = anexoAuxList;
    let atendimentoGlobal = JSON.parse(JSON.stringify(atendimento));
    atendimentoGlobal.anexoAtendimentoReferencialList = atendimentoGlobal.anexoAtendimentoReferencialList.filter((obj) => obj.url !== "");
    this.props.refreshAtendimento(atendimentoGlobal);
    this.anexoReferencialLines[index] = this.createLineAnexo(this.anexoReferencialList[index], index);
    this.setState(this.state);
  };

  uploadAnexo = async (e) => {
    let atendimento = this.state.atendimento;
    if (e.target != null && e.target.files[0] != null) {
      let file = e.target.files[0];
      let formData = new FormData();
      let index = parseInt(e.target.id.split("-")[1]);
      formData.append("name", "file");
      formData.append("file", file);
      formData.append("fileName", file.name);
      if (file.size > 10000000) {
        this.props.showToast("O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.", "error");
        return;
      } else if (
        file &&
        !(
          file.name.toLowerCase().includes("pdf") ||
          file.name.toLowerCase().includes("doc") ||
          file.name.toLowerCase().includes("docx") ||
          file.name.toLowerCase().includes("xls") ||
          file.name.toLowerCase().includes("xlsx")
        )
      ) {
        this.props.showToast("Tipo de arquivo não permitido. Anexar apenas PDF, DOC, DOCX, XLS ou XLSX.", "error");
        return;
      }
      this.props.showLoading();
      await newBackend.post(`anexo-atendimento-referencial/upload/${atendimento.id}/${index + 1}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(async (res) => {
          atendimento.anexoAtendimentoReferencialList[index] = {
            ...atendimento.anexoAtendimentoReferencialList[index],
            id: res.data.id,
            nomeArquivo: res.data.nomearquivo,
            url: res.data.url,
            tipo: res.data.tipo,
            tamanho: res.data.tamanho,
          };
          let anexosExcluidos = this.state.anexosExcluidos.filter(
            (anexo) => anexo.id !== res.data.idanexoreferencial
          );
          this.setState({ anexosExcluidos: anexosExcluidos });
          let atendimentoGlobal = JSON.parse(JSON.stringify(atendimento));
          atendimentoGlobal.anexoAtendimentoReferencialList = atendimentoGlobal.anexoAtendimentoReferencialList.filter((obj) => obj.url !== "");
          atendimentoGlobal.registroAnexo = true;
          await this.props.refreshAtendimento(atendimentoGlobal);
          this.anexoReferencialLines[index] = this.createLineAnexo(this.anexoReferencialList[index], index);
          this.setState(this.state);
          this.props.closeLoading();
          this.props.showToast("Arquivo anexado com sucesso!", "success");
        })
        .catch((error) => {
          this.props.closeLoading();
          this.props.showToast("Erro ao anexar o arquivo. Favor, entrar em contato com o suporte.", "error");
        });
    } else {
      this.props.showToast("Por favor, selecione o tipo do arquivo.", "error");
    }
  };

  createLineAnexo = (anexo, index) => {
    const sort = (anexos) => anexos.sort((i, j) => (i.anexoReferencial.id > j.anexoReferencial.id ? 1 : -1));
    let atendimento = this.state.atendimento;
    let anexos = this.isPrimeiroAcesso
      ? sort(this.state.listAnexos) || []
      : sort(atendimento.anexoAtendimentoReferencialList || []);      
    let anexoAtdRef = !this.isDN ? anexos.find((obj) => obj.anexoReferencial.id === anexo.id) : anexos.find((obj) => obj.anexoReferencial.id === anexo.id && (obj.urlAssinada !== "" || obj.url !== ""));
    if (!anexoAtdRef) {
      anexoAtdRef = this.getAnexoAtendimentoRefModel(anexo);
      anexos.push(anexoAtdRef);
      atendimento.anexoAtendimentoReferencialList = sort(anexos);
      this.setState({ atendimento: atendimento });
    }
    return !this.isDN ? this.createLine(anexoAtdRef, index) : this.createLineAvaliacao(anexoAtdRef, index);
  };

  getAnexoAtendimentoRefModel = (anexo) => {
    return {
      anexoReferencial: anexo,
      nomeArquivo: "",
      urlAssinada: "",
      url: "",
      tipo: "",
      tamanho: "",
    };
  };

  getIconParecer = (iconParecer) => {
    return !this.isDN ? (
      <Grid container className={"avaliar-anexo-referencial"}>
        <Grid item>
          {" "}
          <img src={`./../../assets/icons/${iconParecer}.svg`} alt="" />{" "}
        </Grid>
        <Grid item>
          {" "}
          <label> {iconParecer === "nao-avaliado" ? "não avaliado" : iconParecer} </label>{" "}
        </Grid>
      </Grid>
    ) : null;
  };

  getChip = (anexo, anexoNovo) => {
    return (
      <Chip
        className={"chip-anexo-referencial"}
        label={
          <label>
            <Icon
              className={"attach"}
              style={{ marginRight: "5px" }}
              onClick={() => {
                DownloadFilesNewBackend(anexoNovo.urlAssinada, anexo.nomeArquivo);
              }}
            >
              attach_file
            </Icon>
            <label>{anexo.nomeArquivo}</label>
          </label>
        }
      />
    );
  };

  createLine = (anexo, index) => {
    let referencial = anexo.anexoReferencial;
    let parecerDNOptions = { naoconforme: "reprovado", conforme: "aprovado" };
    let iconParecer = anexo.solicitacaoStatus ? parecerDNOptions[anexo.solicitacaoStatus.chave] : "nao-avaliado";
    return [this.getIconParecer(iconParecer), <b>{referencial.nome}</b>, this.getInputTable(anexo, index), this.getInputTable(anexo, index, true)];
  };

  createLineAvaliacao = (anexo, index, anexoNovo) => {
    let referencial = anexo.anexoReferencial;
    return [<b>{referencial.nome}</b>, this.getChip(anexo, anexoNovo), this.checkParecerDN(anexo, index)];
  };

  getInputTable = (anexo, index, isTrashButton) => {
    return !isTrashButton ? (
      (anexo.anexoReferencial.id && (anexo.urlAssinada !== "" || anexo.url !== "")) ? (
        this.getChip(anexo)
      ) : (
        <div className={"upload-anexo-referencial"}>
          <label className={"lightBlue basic-button"} id={`upload-file-${index}`} htmlFor={`uploadfile-${index}`}>
            {" "}
            Anexar arquivo{" "}
          </label>
          <input
            className={"file-input"}
            type="file"
            id={`uploadfile-${index}`}
            value={undefined}
            onChange={this.uploadAnexo}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </div>
      )
    ) : (
      (anexo.anexoReferencial.id && (anexo.urlAssinada !== "" || anexo.url !== "")) && (
        <div className={"tab-metas-dr"}>
          <GenericButton
            disabled={anexo.solicitacaoStatus && anexo.solicitacaoStatus.chave === "conforme"}
            color={"transparent"}
            click={() => this.removerItem(index)}
            subClass={"icon-and-button"}
            icon={"trash.svg"}
          />
        </div>
      )
    );
  };

  changeInput = (e) => {
    const index = parseInt(e.target.name.split("-")[2]);
    let atendimento = this.state.atendimento;
    let anexo = atendimento.anexoAtendimentoReferencialList[index];
    anexo.solicitacaoStatus = e.target.value === "Conforme" ? this.parecerDNOptions.conforme : this.parecerDNOptions.naoConforme;
    atendimento.anexoAtendimentoReferencialList[index] = anexo;
    this.setState({ atendimento: atendimento });
    this.getAnexosRefresh(index);
  };

  checkParecerDN = (anexo, index) => {
    let solicitacaoStatus = anexo.solicitacaoStatus ? anexo.solicitacaoStatus.descricao : "";
    const setIcon = (icon) => <img src={`./../../assets/icons/${icon}.svg`} alt="" />;
    return (
      <Grid item xs={12}>
        <Grid container style={{ marginBottom: "3%" }} direction={"row"}>
          <Grid item className={"radioDisabled"}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="position" name={`radio-group-${index}`} value={solicitacaoStatus} onChange={this.changeInput} row>
                <Grid container direction={"row"}>
                  <Grid item xs={5} className={"tooltip-solicitacao"}>
                    <span className={"tooltip-info avaliar"}> Aprovar </span>
                    <FormControlLabel
                      value={"Conforme"}
                      control={
                        <Radio
                          color="primary"
                          disabled={false}
                          checkedIcon={setIcon("conforme-checked")}
                          icon={setIcon(solicitacaoStatus === "Não Conforme" ? "conforme-gray" : "conforme-unchecked")}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={5} className={"tooltip-solicitacao"}>
                    <span className={"tooltip-info avaliar"}> Reprovar </span>
                    <FormControlLabel
                      value={"Não Conforme"}
                      control={
                        <Radio
                          color="primary"
                          disabled={false}
                          checkedIcon={setIcon("nao-conforme-checked")}
                          icon={setIcon(solicitacaoStatus === "Conforme" ? "nao-conforme-gray" : "nao-conforme-unchecked")}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  salvarAvaliacao = async () => {
    let atendimento = JSON.parse(JSON.stringify(this.state.atendimento));
    try {
      this.props.showLoading();
      await API.post(`/rest/gestao/Atendimento/${atendimento.id}`, atendimento);
      this.setState({ atendimento: atendimento });
      this.props.refreshAtendimento(atendimento);
      this.props.closeLoading();
      this.props.showToast("Anexos avaliados com sucesso!", "success");
      this.backToResumo();
    } catch (error) {
      this.props.closeLoading();
      this.props.showToast("Erro ao avaliar os anexos. Favor entrar em contato com o suporte.", "error");
    }
  };

  backToResumo = async () => {
    let atendimento = this.state.atendimento;
    try {
      if (this.state.anexosExcluidos.length > 0) {
        this.props.showLoading();
        for (const anexo of this.state.anexosExcluidos) {
          await newBackend.delete(`anexo-atendimento-referencial/${atendimento.id}/${anexo.id}`);
        }
        this.props.closeLoading();
        this.props.showToast("Anexo(s) deletado(s) com sucesso!", "success");
      }
      this.props.showLoading();
      await this.props.atualizarAnexos();
      this.props.closeLoading();
    } catch (error) {
      this.props.closeLoading();
      this.showToast("Infelizmente houve um erro ao deletar o(s) anexo(s). Por favor entre em contato com o suporte.", "error");
    }
    window.scrollTo(0, 0);
    this.props.openAnexosScreen(false);
  };

  render() {
    const icon = (img) => <img className={"tooltipIndicadores"} src={`./../../assets/icons/${img}.svg`} alt="" />;
    const action = this.isDN ? "Avaliar" : "Registrar";
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction={"row"} className={"registar-anexo"} spacing={2}>
            <Grid item className={"lbl-arrow"}>
              {" "}
              <label className={"left"}> Resumo do Atendimento </label>{" "}
            </Grid>
            <Grid item className={"arrow-indicadores"}>
              {" "}
              {icon("arrow-registro-indicadores")}{" "}
            </Grid>
            <Grid item className={"lbl-arrow"}>
              {" "}
              <label className={"right"}>{action} Anexos</label>{" "}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "2%" }}>
          <Table
            cellWidth={this.isDN ? "30%" : "auto"}
            lines={this.anexoReferencialLines}
            types={this.types}
            titles={!this.isDN ? this.registroTitles : this.avaliacaoTitles}
            initialOrder={"1"}
            pagination={false}
          ></Table>
        </Grid>
        {!this.isDN && (
          <Grid item container xs={12} justify={"center"} style={{ marginTop: "2%" }}>
            <Grid item xs={2} className={"rodape"} style={{ display: "flex", justifyContent: "center" }}>
              <GenericButton
                color={"darkBlue-outline"}
                label={"Voltar"}
                subClass={"basic-button"}
                click={() => {
                  this.backToResumo();
                }}
              />
            </Grid>
          </Grid>
        )}
        {this.anexoReferencialLines.length > 0 && this.isDN && (
          <Grid item container xs={12} justify={"center"} style={{ marginTop: "2%" }}>
            <Grid item xs={2} className={"rodape"} style={{ display: "flex", justifyContent: "center" }}>
              <GenericButton
                color={"darkBlue-outline"}
                label={"Cancelar"}
                subClass={"basic-button"}
                click={() => {
                  this.backToResumo();
                }}
              />
            </Grid>
            <Grid item xs={2} className={"rodape"} style={{ display: "flex", justifyContent: "center" }}>
              <GenericButton color={"darkBlue"} label={action} subClass={"basic-button"} click={this.salvarAvaliacao} />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}
